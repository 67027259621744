.login-modal {
  .modal-dialog {
    display: flex;
    margin: auto;
  }
  .modal-dialog {
    display: flex;
    height: 100vh;
    max-width: initial;
  }
}