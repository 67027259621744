@import '../../scss/global/Global';

.items-collection-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
  .item-collection {
    flex: 0 0 31.33%;
    margin: 1%;
    background-color: white;
    .image {
      position: relative;
      img {
        margin: 0px !important;
        width: 100% !important;
      }
      .title {
        position: absolute;
        top: 0px;
        margin: 0px;
        padding: 0px;
        background-color: rgba(180, 180, 180, 0.7);
        padding: 10px;
        color: white;
      }
    }
    @include transition(all 0.5s ease-in-out);
    @include respond-to(xl) {
      flex: 0 0 31.33%;
    }
    @include respond-to(lg) {
      flex: 0 0 31.33%;
    }
    @include respond-to(md) {
      flex: 0 0 31.33%;
    }
    @include respond-to(sm) {
      flex: 0 0 48%;
    }
    @include respond-to(xs) {
      flex: 0 0 48%;
    }
    @include respond-to(xxs) {
      flex: 0 0 100%;
    }
  }
}