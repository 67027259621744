.confirm-booking-modal {
  .modal-dialog {
    display: flex;
    margin: auto;
  }
  .modal-dialog {
    display: flex;
    height: 100vh;
    max-width: initial;
    .modal-content {
      margin: auto;
      max-width: 500px !important;
      min-width: 300px !important;
      width: 90%;
      padding-top: 15px;
      padding-bottom: 15px;
      pointer-events: all !important;
    }
  }
}