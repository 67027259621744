@import '../../scss/global/Global';

.basket-summary {
  position: sticky;
  top: 45px;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 5px #e2e2e2;
  border: 1px solid #e9e8e8;
}