@import "../../scss/global/Global";

footer {
  color: white;
  background: black;
  padding-top: 10px;
  .container {
    h4 {
      border-bottom: 1px solid $GREEN-A;
      padding-bottom: 5px;
      margin-bottom: 5px;
      float: left;
      clear: both;
    }
    small {
      clear: both;
      width: 100%;
      float: left;
      margin-bottom: 10px;
      font-size: 14px;
    }
    a {
      color: white;
      text-decoration: underline;
    }
    a:hover {
      color: white;
      text-decoration: none;
    }
    .social-network {
      small {
        .shares {
          display: flex;
          img {
            width: 45px;
            margin-right: 5px;
          }
        }
      }
    }
    .copy-right {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    img.payments {
      width: 100%;
    }
    .footer-logo {
      display: flex;
      font-size: 1.3em;
      img {
        width: 40px;
        margin-right: 3px;
      }
    }
  }
}
