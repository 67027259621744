@import '../../scss/global/Global';

.login-formik {
  margin: auto;
  max-width: 500px !important;
  min-width: 300px !important;
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
  pointer-events: all !important;
}