@import '../../scss/global/Global';
.root {
  height: 100vh;
}
.wrap {
  min-height: calc(100vh - 242px);
  background: white;
  &.resend-activation,
  &.activate,
  &.signup,
  &.login,
  &.forgot-password,
  &.reset-password{
    background: rgb(0,36,1);
    background: linear-gradient(90deg, rgba(0,36,1,1) 0%, rgba(9,121,21,1) 100%, rgba(0,0,0,1) 100%);
  }
  @include respond-to(xxs){
    min-height: calc(100vh - 428px);
  }
  @include respond-to(xs){
    min-height: calc(100vh - 307px);
  }
  @include respond-to(sm){
    min-height: calc(100vh - 314px);
  }
  @include respond-to(md){
    min-height: calc(100vh - 242px);
  }
  @include respond-to(lg){
    min-height: calc(100vh - 242px);
  }
  @include respond-to(xl){
    min-height: calc(100vh - 242px);
  }
  // @include respond-to(md){
  //   min-height: calc(100vh - 307px);
  // }
}

.footer,
.push {
  height: 50px;
}
