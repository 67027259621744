@import '../../scss/global/Global';

.book-collection.formik {
  margin: 0px !important;
  form {
    margin: 0px !important;
    margin-top: 0px !important;
    box-shadow: none;
  }
}

.formik {

  .form-group {
    input,
    textarea {
      background: white !important;
    }
    .row div:first-child {
      margin-bottom: 1em !important;
    }
    .pl-1,
    .pr-1 {
      @include respond-to(xxs){
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      @include respond-to(xs){
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}