.basket-item {
    background: white;
    margin-bottom: 10px;
    box-shadow: 0px 0px 5px #e2e2e2;
    border: 1px solid #e9e8e8;
    .align-center {
        align-items: center;
    }
    .quantity-container {
        align-items: center;
        .input-group {
            align-items: center;
        }
        button {
            height: 20px;
            width: 20px;
            font-size: 10px;
            padding: 0px;
            margin: 0px;
            line-height: 0px;
            border-radius: 50px;
            border: none;
            text-align: center;
            &:first-child {
                margin-right: 5px;
            }
            &:last-child {
                margin-left: 5px;
            }
        }
    }
    .align-right {
        text-align: right;
        justify-content: flex-end;
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
    img {
        margin: 0px;
    }
}
