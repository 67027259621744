.alerts {
  position: fixed;
  min-width: 320px;
  max-width: 520px;
  width: 90%;
  margin: auto;
  top: 10px;
  left: 0px;
  right: 0px;
  z-index: 999999;
}