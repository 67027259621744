@import '../../../scss/global/Global';

.product{
    margin: 1%;
    background: white;
    width: 23%;
    @include transition(all 0.5s ease-in-out);
    @include respond-to(xl) {
        width: 23%;
    }
    @include respond-to(lg) {
        width: 23%;
    }
    @include respond-to(md) {
        width: 31.33%;
    }
    @include respond-to(sm) {
        width: 48%;
    }
    @include respond-to(xs) {
        width: 48%;
    }
    @include respond-to(xxs) {
        width: 100%;
    }
    a {
        .image {
            position: relative;
            height: 170px;
            overflow: hidden;
            img {
                position: absolute;
                height: 100%;
                width: auto !important;
                float: none;
                margin: auto;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%)
            }
            .price {
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 1;
                background: $BLUE-A;
                color: white;
                padding: 5px;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 50px;
            }
        }

        .title{
            padding-top: 10px;
            padding-bottom: 10px;
            clear: both;
            text-align: center;
            background: $GREEN-A;
            color: white;
            font-weight: 400;
        }

        &:hover {
            .title {
                text-decoration: underline;
            }
        }
    }
    img {
        width: 100%;
        margin: 0px;
    }

    &:hover {
        transform: scale(1.05);
    }
}