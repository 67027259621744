@import "./scss/global/Global";

html,
body,
.root {
  height: 100%;
  margin: 0;
  font-size: 18px;
  @include respond-to(xxs){
    font-size: 16px;
  }
  @include respond-to(xs){
    font-size: 16px;
  }
}

html,
body {
  padding-right: 0px !important;
  background: rgb(0,36,1);
  background: linear-gradient(90deg, rgba(0,36,1,1) 0%, rgba(9,121,21,1) 100%, rgba(0,0,0,1) 100%);
}