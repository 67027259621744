@import '../../scss/global/Global';

.recycle-scheme {
  img {
    width: 100%;
    margin: 0px;
  }
  section {
    clear: both;
  }
  .green {
    background-color: $GREEN-A;
  }
  .white {
    background-color: $WHITE-A;
  }
  .grey {
    background: $GREY-A;
  }
}